import {createTheme} from '@material-ui/core/styles'
import {RaThemeOverrides} from 'react-admin'

import {capitalize} from '../utils/strings'
// TODO: Inline the boxShadow definitions directly where used, then delete that file
// TODO: Figure out if this box shadow customization has any valuable effect to the UI,
//       if not, simple remove them!
import boxShadows from './boxShadows'
import breakpoints from './breakpoints'
import hexToRgb from './hexToRgb'
import palette from './palette'
import typography from './typography'

const patchPaletteColorProperty = (component: string) => Object.assign(
  {},
  ...Object.keys(palette).filter(color => !!palette[color].main).map(color => ({
    [`&.${component}-color${capitalize(color)}`]: {
      color: palette[color].main,
    },
  })),
)

const patchPaletteBackgroundColorAndColorProperties = (
  component: string, colorPrefix = '',
) => Object.assign(
  {},
  ...Object.keys(palette).filter(color => !!palette[color].main).map(color => ({
    [`&.${component}-${colorPrefix}${capitalize(color)}`]: {
      backgroundColor: `rgba(${hexToRgb(palette[color].main)}, .1)`,
      color: palette[color].main,
    },
  })),
)


const customOverrides = {
  MuiAccordion: {
    root: {
      border: `1px solid ${palette.background.paper}`,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  MuiAccordionSummary: {
    root: {
      minHeight: '4rem',
    },
  },
  MuiAlert: {
    icon: {
      '& .MuiSvgIcon-root': {
        alignSelf: 'center',
      },
    },
    message: {
      margin: 'auto 0',
    },
    root: {
      ...patchPaletteBackgroundColorAndColorProperties('MuiAlert', 'standard'),
      border: 'solid 1px',
    },
  },
  MuiAvatarGroup: {
    avatar: {
      '&:hover': {
        zIndex: '6!important',
      },
      border: `2px solid ${palette.background.paper}`,
      marginLeft: '-.75rem',
      position: 'relative',
      zIndex: '5!important',
    },
  },
  MuiPaginationItem: {
    outlined: {
      '&:hover': {
        backgroundColor: palette.disabled.main,
      },
      border: `1px solid ${palette.disabled.main}`,
      color: palette.primary.main,
    },
    outlinedPrimary: {
      '&.Mui-selected': {
        '&, &:hover': {
          backgroundColor: `${palette.primary.main}!important`,
          boxShadow: boxShadows.buttonBoxShadow,
          color: palette.background.paper,
        },
      },
    },
    root: {
      '&:hover': {
        backgroundColor: palette.disabled.main,
      },
      border: `1px solid ${palette.disabled.main}`,
      borderRadius: '50%',
      color: palette.secondary.main,
      fontSize: '.875rem',
      height: '36px',
      width: '36px',
    },
    sizeLarge: {
      borderRadius: '50%',
      height: '46px',
      lineHeight: '46px',
      width: '46px',
    },
    sizeSmall: {
      borderRadius: '50%',
      height: '30px',
      lineHeight: '30px',
      width: '30px',
    },
  },
  MuiTabPanel: {
    root: {
      padding: '0',
    },
  },
  RaBulkActionsToolbar: {
    toolbar: {
      backgroundColor: 'unset',
    },
  },
  RaButton: {
    mediumIcon: {
      fontSize: '1.5rem',
    },
  },
  RaCreate: {
    root: {
      // TODO: Use "remSpacing()" as well as everywhere in the base theme
      //  files ("pxToRem()" in typography.js...).
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      [breakpoints.up('lg')]: {
        maxWidth: '50%',
      },
    },
  },
  RaDatagrid: {
    headerCell: {
      backgroundColor: palette.info.light,
      top: '-1px',
      verticalAlign: 'middle',
      zIndex: 1000,
    },
    headerRow: {
      height: '4rem',
    },
    rowCell: {
      '& .MuiTypography-root': {
        color: palette.text.primary,
        fontSize: typography.body1.fontSize,
      },
      verticalAlign: 'middle',
    },
    table: {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          textAlign: 'start',
        },
        '& .MuiTableCell-root:first-child': {
          paddingLeft: '2rem',
          textAlign: 'start',
          [breakpoints.up('lg')]: {
            paddingLeft: '2.5rem',
          },
        },
        // When the last row cell is also has ".column-editIconButton" class.
        '& .MuiTableCell-root:last-child.column-editIconButton': {
          textAlign: 'end',
        },
        '& .MuiTableCell-root:last-child.column-showIconButton': {
          textAlign: 'end',
        },
      },
      '& .MuiTableSortLabel-icon': {
        color: `${palette.info.main} !important`,
      },
    },
  },
  RaEdit: {
    noActions: {
      marginTop: 0,
    },
    root: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
      [breakpoints.up('lg')]: {
        maxWidth: '50%',
      },
    },
  },
  RaFilterForm: {
    form: {
      alignItems: 'center',
    },
  },
  RaFormInput: {
    input: {
      width: '100%',
    },
  },
  RaLabeled: {
    label: {
      color: palette.text.primary,
      transform: 'none',
      ...typography.body1,
    },
    value: {
      padding: 0,
    },
  },
  RaList: {
    bulkActionsDisplayed: {
      marginTop: 0,
    },
    main: {
      '& table.MuiTable-root': {
        '& .MuiTableCell-root': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        /* We always want (horizontally) fixed sized tables, as otherwise tables with
           columns that have very wide widths will expand horizontally indefinitely.
           Especially, but not only, we never want horizontally scrolling tables on
           mobile screen sizes. We rather reduce the number of columns on smaller screens
           than scroll tables horizontally.
         */
        tableLayout: 'fixed',
      },
    },
  },
  RaListToolbar: {
    toolbar: {
      backgroundColor: `${palette.background.paper} !important`,
      padding: '0 !important',
    },
  },
  RaSaveButton: {
    button: {
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
    },
  },
  RaSearchInput: {
    input: {
      marginTop: '8px',
    },
  },
  RaShow: {
    root: {
      '& .MuiFormLabel-root': {
        color: palette.text.secondary,
        ...typography.caption,
      },
      [breakpoints.up('lg')]: {
        maxWidth: '50%',
      },
      '& .ra-field': {
        marginBottom: '2rem',
      },
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
  },
  RaSingleFieldList: {
    root: {
      marginBottom: 'unset',
      marginTop: 'unset',
    },
  },
  RaToolbar: {
    toolbar: {
      backgroundColor: palette.background.paper,
      margin: 0,
      padding: 0,
    },
  },
  RaTopToolbar: {
    root: {
      backgroundColor: `${palette.background.paper} !important`,
      paddingTop: 0,
    },
  },
} as RaThemeOverrides

const theme = createTheme({
  breakpoints,
  overrides: {
    ...customOverrides,
    MuiAvatar: {
      img: {
        borderRadius: '50%',
        borderStyle: 'none',
        verticalAlign: 'middle',
        width: '100%',
      },
      root: {
        alignItems: 'center',
        backgroundColor: palette.disabled.main,
        borderRadius: '50%',
        color: palette.background.paper,
        display: 'inline-flex',
        fontSize: typography.body2.fontSize,
        height: '48px',
        justifyContent: 'center',
        width: '48px',
      },
    },
    MuiBadge: {
      badge: {
        backgroundColor: palette.primary.main,
        border: '0',
        borderRadius: '1rem',
        color: palette.primary.contrastText,
        display: 'inline-block',
        fontSize: '66%',
        fontWeight: 600,
        height: 'unset',
        lineHeight: '1',
        padding: '.35rem .375rem',
        textAlign: 'center',
        textTransform: 'uppercase',
        transition:
          `color .15s ease-in-out,background-color .15s ease-in-out,border-color
          .15s ease-in-out,box-shadow .15s ease-in-out`,
        verticalAlign: 'baseline',
        whiteSpace: 'nowrap',
      },
      colorError: {
        backgroundColor: palette.error.main,
        color: palette.error.contrastText,
      },
      colorPrimary: {
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
      },
      colorSecondary: {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
      },
      dot: {
        position: 'absolute',
        right: 3,
        top: 7,
      },
      root: {
        textDecoration: 'none',
      },
    },
    MuiButton: {
      contained: {
        '&:hover': {
          backgroundColor: `${palette.info.dark} !important`,
          borderColor: `${palette.info.dark} !important`,
        },
        backgroundColor: palette.info.main,
        border: `1px solid ${palette.info.main}`,
        boxShadow: 'none !important',
        color: palette.info.contrastText,
      },
      containedPrimary: {
        '&:disabled': {
          borderColor: `${palette.disabled.main}`,
        },
        backgroundColor: palette.info.main,
      },
      // TODO: This previously unused color now styles `error` colored
      //       buttons. Ideally, we would like to use <Button color='error' />
      //       but this is not supported
      containedSecondary: {
        '&:hover': {
          backgroundColor: `${palette.error.dark} !important`,
          borderColor: palette.error.dark,
        },
        backgroundColor: palette.error.main,
        borderColor: palette.error.main,
      },
      containedSizeLarge: {
        borderRadius: '.4375rem',
        padding: '.875rem 1rem',
        ...typography.body2,
      },
      containedSizeSmall: {
        borderRadius: '.375rem',
        padding: '.25rem .5rem',
        ...typography.caption,
      },
      outlined: {
        '&:hover': {
          backgroundColor: palette.info.main,
          boxShadow: 'none',
          color: palette.info.contrastText,
        },
        borderColor: palette.info.main,
        boxShadow: 'none',
        color: palette.info.main,
        padding: '.625rem 1.25rem',
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: palette.info.main,
          borderColor: palette.info.main,
          color: palette.background.paper,
        },
        borderColor: palette.info.main,
        color: palette.info.main,
      },
      outlinedSecondary: {
        '&:hover': {
          backgroundColor: palette.secondary.main,
          borderColor: palette.secondary.main,
          color: palette.primary.main,
        },
        backgroundColor: 'transparent',
        borderColor: palette.secondary.main,
        color: palette.primary.main,
      },
      outlinedSizeLarge: {
        borderRadius: '.4375rem',
        padding: '.875rem 1rem',
        ...typography.body2,
      },
      outlinedSizeSmall: {
        borderRadius: '.375rem',
        padding: '.25rem .5rem',
        ...typography.caption,
      },
      root: {
        '& .MuiButton-label': {
          display: 'flex',
        },
        '& > .MuiButton-label > .MuiSvgIcon-root:not(:last-child)': {
          // Add margin between button icon and label text, if button is not icon-only
          marginRight: '0.5rem',
        },
        '& > .MuiButton-label > span': {
          // Overwrite react-admin's `RaButton` padding-left as the icon already adds a
          // margin-right prop; there's no need to combine both settings. At the end we
          // don't use react-admin's RaButton component very often, that's why gravitate
          // the CSS props on regular material-ui usage and that is why adding a
          // margin-right to button icons is semantically stronger (see above)
          paddingLeft: 0,
        },
        '&.Mui-disabled': {
          '& svg': {
            filter: 'grayscale(1) !important',
            opacity: '.5 !important',
          },
        },
        borderRadius: '.375rem',
        boxShadow: 'none',
        color: palette.primary.main,
        display: 'inline-block',
        letterSpacing: '.025em',
        margin: '0.3rem',
        padding: '.625rem 1.25rem',
        position: 'relative',
        textAlign: 'center',
        textTransform: 'none',
        transition: 'all .15s ease',
        userSelect: 'none',
        verticalAlign: 'middle',
        willChange: 'transform',
        ...typography.body1,
      },
      text: {
        padding: '.625rem 1.25rem',
      },
    },
    MuiCard: {
      root: {
        backgroundClip: 'initial',
        backgroundColor: palette.background.paper,
        border: '0',
        borderRadius: '.375rem',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        overflow: 'unset',
        position: 'relative',
        wordWrap: 'break-word',
      },
    },
    MuiCardActions: {
      root: {
        '&:last-child': {
          backgroundColor: palette.background.paper,
          borderRadius: '0 0 calc(.375rem - 1px) calc(.375rem - 1px)',
          borderTop:
            `1px solid rgba(${hexToRgb(palette.primary.main)},.05)`,
          padding: '1.25rem 1.5rem',
        },
      },
    },
    MuiCardContent: {
      root: {
        flex: '1 1 auto',
        minHeight: '1px',
        padding: '0',
      },
    },
    MuiCardHeader: {
      root: {
        '&:first-child': {
          borderRadius: 'calc(.375rem - 1px) calc(.375rem - 1px) 0 0',
        },
        backgroundColor: palette.background.paper,
        borderBottom:
          `1px solid rgba(${hexToRgb(palette.primary.main)},.05)`,
        marginBottom: '0',
        padding: '1.25rem 1.5rem',
      },
    },
    MuiCheckbox: {
      root: {
        '& .MuiSvgIcon-root': {
          height: '1.5rem',
          width: '1.5rem',
        },
        '&.Mui-checked': {
          color: `${palette.info.main} !important`,
        },
        '&:hover': {
          backgroundColor: 'initial',
          border: 'initial',
          color: palette.secondary.main,
        },
        border: 'initial',
      },
    },
    MuiChip: {
      deleteIcon: {
        '&:hover': {
          color: palette.info.dark,
        },
      },
    },
    MuiContainer: {
      maxWidthLg: {
        [breakpoints.up('lg')]: {
          maxWidth: '960px',
        },
      },
      maxWidthMd: {
        [breakpoints.up('md')]: {
          maxWidth: '720px',
        },
      },
      maxWidthSm: {
        [breakpoints.up('sm')]: {
          maxWidth: '540px',
        },
      },
      maxWidthXl: {
        [breakpoints.up('xl')]: {
          maxWidth: '1140px',
        },
      },
      maxWidthXs: {
        [breakpoints.up('sm')]: {
          maxWidth: '540px',
        },
      },
      root: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '15px',
        paddingRight: '15px',
        width: '100%',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: palette.background.paper,
        width: '100%',
        [breakpoints.up('lg')]: {
          maxHeight: 'min(1024px, calc(100% - 64px)) !important',
        },
      },
    },
    MuiDialogActions: {
      root: {
        alignItems: 'center',
        borderBottomLeftRadius: '.4375rem',
        borderBottomRightRadius: '.4375rem',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        padding: '1.25rem',
      },
    },
    MuiDialogContent: {
      root: {
        '& .edit-page': {
          maxWidth: 'none',
          paddingLeft: 0,
          paddingRight: 0,
        },
        '& .show-page': {
          '& .MuiTableCell-root:first-child': {
            paddingLeft: '0 !important',
          },
          '& .MuiTableCell-root:last-child': {
            textAlign: 'initial',
          },
          maxWidth: 'none',
          paddingLeft: 0,
          paddingRight: 0,
        },
        flex: '1 1 auto',
        padding: '1.5rem',
        position: 'relative',
        [breakpoints.up('lg')]: {
          padding: '1.5rem 2rem',
        },
      },
    },
    MuiDialogContentText: {
      root: {
        '&:not(:first-child)': {
          paddingTop: '2rem',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        '& > h2.MuiTypography-h6': {
          ...typography.h3,
        },
        [breakpoints.up('lg')]: {
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '2rem',
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: `rgba(${hexToRgb(palette.primary.main)}, 0.1)`,
      },
    },
    MuiDrawer: {
      docked: {
        width: '250px',
      },
      paper: {
        paddingBottom: '1rem',
        paddingTop: '1rem',
        width: '250px',
      },
      paperAnchorDockedLeft: {
        borderRight: 'none',
        boxShadow: `0 0 2rem 0 rgba(${hexToRgb(palette.secondary.main)},.15) !important`,
      },
    },
    MuiFab: {
      primary: {
        '&:hover': {
          backgroundColor: palette.info.dark,
        },
        backgroundColor: palette.info.main,
      },
      root: {
        [breakpoints.up('lg')]: {
          bottom: '24px',
        },
        // TODO: Figure out using rem instead of px.
        bottom: '55px',
        margin: 0,
        position: 'absolute',
        right: '20px',
        zIndex: 1000,
      },
    },
    MuiFilledInput: {
      adornedStart: {
        gap: '.625rem',
        marginTop: '.625rem',
      },
      input: {
        '&:not(:first-child)': {
          borderBottomLeftRadius: 0,
          borderLeft: 0,
          borderTopLeftRadius: 0,
        },
        border: 0,
        boxShadow: 'none',
        flex: '1 1 auto',
        marginBottom: 0,
        minWidth: 0,
        padding: '.625rem .75rem',
        position: 'relative',
        width: '1%',
      },
      inputAdornedEnd: {
        paddingRight: '.75rem',
      },
      inputAdornedStart: {
        paddingLeft: '.75rem',
      },
      multiline: {
        backgroundColor: palette.background.paper,
        padding: '.625rem .75rem',
      },
      root: {
        alignItems: 'center',
        backgroundColor: `${palette.background.paper}!important`,
        border: 0,
        borderRadius: 0,
        boxShadow: boxShadows.inputBoxShadow,
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
        transition: 'box-shadow .15s ease',
        width: '100%',
      },
      underline: {
        '&:after': {
          border: `2px solid ${palette.info.main}`,
          borderBottom: `2px solid ${palette.info.main}`,
          borderRadius: '.375rem',
          height: '100%',
        },
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiFormControlLabel: {
      label: {
        lineHeight: 'unset',
      },
      root: {
        // As we don't want input label to be clickable we activate pointerEvents
        // only for input components.
        '& .MuiSwitch-root': {
          pointerEvents: 'auto',
        },
        '&:has(> .MuiSwitch-root)': {
          pointerEvents: 'none',
        },
      },
    },
    MuiFormGroup: {
      root: {
        marginBottom: '1.5rem',
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-disabled': {
          color: palette.text.secondary,
        },
        color: palette.primary.main,
        display: 'inline-block',
        fontSize: '.875rem',
        fontWeight: 600,
        marginBottom: '.5rem',
      },
    },
    MuiGrid: {
      container: {
        '& > .MuiDivider-vertical.MuiDivider-flexItem': {
          // Fix broken layouting: https://stackoverflow.com/a/68832210/543875
          marginRight: '-1px',
        },
        marginLeft: '-15px',
        marginRight: '-15px',
        width: 'unset',
      },
      item: {
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: palette.info.main,
          border: `solid 2px ${palette.background.paper}`,
          color: palette.background.paper,
        },
        border: `solid 2px ${palette.info.main}`,
        color: palette.info.main,
      },
      root: {
        '&$disabled': {
          '& .MuiSvgIcon-root': {
            color: palette.disabled.main,
          },
          '&& $label': {
            color: palette.disabled.main,
          },
        },
      },
    },
    MuiInputAdornment: {
      filled: {
        '&$positionStart': {
          border: '0',
          borderBottomRightRadius: '0',
          borderTopRightRadius: '0',
          boxShadow: 'none',
          marginRight: '-1px',
        },
        display: 'flex',
      },
      positionEnd: {
        paddingLeft: 0,
      },
      positionStart: {
        paddingLeft: 0,
      },
      root: {
        alignItems: 'center',
        backgroundColor: palette.background.paper,
        border: '0',
        color: palette.secondary.main,
        display: 'flex',
        fontSize: '.875rem',
        fontWeight: 400,
        height: 'calc(1.5em + 1.25rem + 2px)',
        lineHeight: '1.5',
        marginBottom: '0',
        marginTop: '0!important',
        padding: '.625rem 0',
        textAlign: 'center',
        transition: 'all .2s cubic-bezier(.68,-.55,.265,1.55)',
        whiteSpace: 'nowrap',
      },
    },
    MuiInputBase: {
      input: {
        '&::-webkit-date-and-time-value': {
          paddingLeft: '4px',
          paddingTop: '23px',
          textAlign: 'start',
        },
        '&[type="time"]': {
          // At least on Firefox there's always too much right padding unless set
          // explictly to 0. The original padding led to input text being cut off at the
          // right end of the input. Given that we want time inputs to always show its
          // entire text, having a zero padding has otherwise no negative impact.
          paddingRight: '0 !important',
        },
        backgroundClip: 'padding-box',
        backgroundColor: palette.background.paper,
        border: '1px solid #cad1d7',
        borderRadius: '0',
        boxShadow: 'none',
        boxSizing: 'border-box',
        color: palette.text.primary,
        display: 'block',
        fontSize: typography.body1.fontSize,
        fontWeight: 400,
        height: '100%',
        lineHeight: 1.5,
        padding: '.625rem .75rem !important',
        transition: 'all .2s cubic-bezier(.68,-.55,.265,1.55)',
        width: '100%',
      },
      marginDense: {
        marginBottom: 0,
        marginTop: 0,
      },
      root: {
        '& .Mui-disabled': {
          backgroundColor: palette.background.main,
          cursor: 'text',
          opacity: '.5 !important',
        },
        minHeight: '3.75rem',
        paddingLeft: '0 !important',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 15px) scale(1)',
      },
      root: {
        '&$error, &$focused': {
          opacity: 1,
        },
        color: palette.text.secondary,
        fontSize: typography.body1.fontSize,
        fontWeight: 400,
      },
    },
    MuiLinearProgress: {
      bar: {
        borderRadius: 0,
        boxShadow: 'none',
        color: palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        justifyContent: 'center',
        overflow: 'hidden',
        textAlign: 'center',
        transition: 'width .6s ease',
        whiteSpace: 'nowrap',
      },
      root: {
        backgroundColor: `${palette.background.secondary }!important`,
        borderRadius: '.25rem',
        boxShadow: boxShadows.linearProgressBoxShadow,
        display: 'flex',
        fontSize: '.75rem',
        height: '8px',
        lineHeight: '0',
        marginBottom: '1rem',
        overflow: 'hidden',
      },
    },
    MuiLink: {
      root: {
        '&:hover': {
          textDecoration: 'underline',
        },
        color: `${palette.info.main} !important`,
        cursor: 'pointer',
        fontWeight: 600,
        textDecoration: 'unset',
      },
      underlineHover: {
        '& .MuiTypography-root': {
          color: `${palette.info.main} !important`,
        },
        padding: 'unset',
        textDecoration: 'underline',
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '.75rem',
        paddingRight: '.75rem',
      },
      root: {
        '& .Mui-selected': {
          backgroundColor: palette.background.secondary,
        },
        color: palette.primary.main,
        display: 'block',
        paddingBottom: '.25rem',
        paddingTop: '.25rem',
      },
    },
    MuiListItemText: {
      root: {
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
      },
    },
    MuiMenu: {
      paper: {
        backgroundClip: 'padding-box',
        backgroundColor: palette.background.paper,
        border: `0 solid rgba(${hexToRgb(palette.primary.main) },.15)`,
        borderRadius: '.4375rem',
        boxShadow: boxShadows.menuBoxShadow,
        color: palette.primary.main,
        fontSize: typography.body1.fontSize,
        listStyle: 'none',
        minWidth: '12rem',
        padding: '.5rem 0',
        textAlign: 'left',
      },
    },
    MuiMenuItem: {
      root: {
        '& i': {
          fontSize: typography.body1.fontSize,
          marginRight: '1rem',
          verticalAlign: '-17%',
        },
        '&, & > .MuiListItemText-root > .MuiListItemText-primary': {
          fontSize: '.875rem',
          fontWeight: 400,
        },
        backgroundColor: 'initial',
        border: 0,
        clear: 'both',
        color: palette.text.primary,
        display: 'flex', // horizontally align subelements such as an icon and text
        padding: '.5rem 1rem',
        textAlign: 'inherit',
        whiteSpace: 'nowrap',
        width: '100%',
      },
    },
    MuiOutlinedInput: {
      input: {
        '&:not(:last-child)': {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          paddingRight: 0,
        },
        borderBottom: '0',
        borderLeft: '0',
        borderRight: '0',
        borderTop: '0',
        color: palette.primary.main,
        padding: '.625rem .75rem',
      },
      multiline: {
        height: '100%',
      },
      notchedOutline: {
        borderColor: palette.disabled.main,
      },
      root: {
        borderRadius: '2px',
        height: 'calc(1.8em + 1.25rem + 2px)',
        paddingRight: '0',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        color: palette.primary.main,
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '0.625rem',
        boxShadow: boxShadows.popoverBoxShadow,
        color: palette.primary.main,
        fontSize: '0.875rem',
        padding: '0.5rem 0.95rem',
      },
    },
    MuiRadio: {
      root: {
        '& .MuiSvgIcon-root': {
          height: '1.5rem',
          width: '1.5rem',
        },
      },
    },
    MuiSelect: {
      icon: {
        color: palette.text.secondary,
      },
      root: {
        alignItems: 'center',
        display: 'flex',
        height: '3.75rem !important',
      },
    },
    MuiSlider: {
      rail: {
        backgroundColor: palette.disabled.main,
        borderRadius: '5px',
        boxShadow: boxShadows.sliderBoxShadow,
      },
      root: {
        borderRadius: '5px',
      },
    },
    MuiSnackbarContent: {
      message: {
        display: 'flex',
        padding: '0',
      },
      root: {
        border: '1px solid transparent',
        borderRadius: '.375rem',
        fontSize: '.875rem',
        marginBottom: '1rem',
        padding: '1rem 1.5rem',
        position: 'relative',
      },
    },
    MuiSvgIcon: {
      root: {
        ...patchPaletteColorProperty('MuiSvgIcon'),
      },
    },
    MuiSwitch: {
      switchBase: {
        '&$checked': {
          '& $thumb': {
            backgroundColor: palette.info.main,
          },
          '& + $track': {
            backgroundColor: `${palette.info.main }!important`,
            borderColor: palette.info.main,
          },
        },
      },
    },
    MuiTab: {
      root: {
        [breakpoints.down('xs')]: {
          fontSize: typography.body2.fontSize,
        },
        '&:focus': {
          opacity: 1,
        },
        fontSize: typography.body1.fontSize,
        fontWeight: typography.fontWeightRegular,
        minWidth: '0 !important', // overrides a media query setting
        textTransform: 'none',
      },
      textColorInherit: {
        '&$selected': {
          color: palette.text.primary,
        },
        color: palette.text.secondary,
        opacity: 1,
      },
      wrapper: {
        padding: 0,
      },
    },
    MuiTable: {
      root: {
        backgroundColor: 'transparent',
        borderCollapse: 'collapse',
        color: palette.primary.main,
        marginBottom: '1rem',
        width: '100%',
      },
    },
    MuiTableCell: {
      head: {
        borderBottom: `1px solid ${palette.background.default}`,
        borderTop: `1px solid ${palette.background.default}`,
        color: palette.text.primary,
        fontSize: typography.body1.fontSize,
        fontWeight: 600,
        letterSpacing: '1px',
        padding: '1rem',
        paddingBottom: '.75rem',
        paddingTop: '.75rem',
        textTransform: 'capitalize',
        verticalAlign: 'bottom',
        whiteSpace: 'nowrap',
        ...typography.subtitle2,
      },
      paddingCheckbox: {
        '&.MuiTableCell-root:first-child': {
          paddingRight: '1rem',
        },
        verticalAlign: 'middle',
      },
      root: {
        borderBottom: `1px solid ${palette.background.default}`,
        borderTop: `1px solid ${palette.background.default}`,
        fontSize: '.8125rem',
        padding: '1rem',
        verticalAlign: 'top',
        whiteSpace: 'nowrap',
      },
    },
    MuiTablePagination: {
      root: {
        '& .MuiTypography-root': {
          color: palette.text.secondary,
          fontSize: typography.body1.fontSize,
        },
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MuiTabs: {
      indicator: {
        '& > span': {
          backgroundColor: palette.primary.main,
          width: '85%',
        },
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
      },
      root: {
        display: 'flex',
        flexShrink: 0, // Stop cutting off multi-line tab labels
      },
    },
    MuiToolbar: {
      root: {
        marginLeft: '1.5rem',
        marginRight: '1.5rem',
      },
    },
    MuiTooltip: {
      arrow: {
        color: palette.background.paper,
      },
      tooltip: {
        backgroundColor: palette.background.paper,
        borderRadius: '0.375rem',
        boxShadow: '0px 0.125rem 0.625rem rgba(0, 0, 0, 0.25)',
        color: palette.primary.main,
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.5,
        maxWidth: '360px',
        padding: '0.25rem 0.5rem',
        textAlign: 'left',
      },
    },
    MuiTypography: {
      colorSecondary: {
        color: palette.info.main,
      },
      overline: {
        textTransform: 'unset',
      },
      root: {
        '& > *': {
          verticalAlign: 'middle',
        },
        '& > .MuiSvgIcon-root': {
          marginRight: '.5em',
        },
      },
    },
  },
  palette,
  typography,
})

theme.hexToRgb = hexToRgb
theme.remSpacing = spacing => theme.typography.pxToRem(theme.spacing(spacing))

export type AppTheme = typeof theme
export {breakpoints}

export default theme
