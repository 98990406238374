import {merge} from 'lodash'
import germanMessages from 'ra-language-german'

export default {
  ...merge(germanMessages, {
    ra: {
      action: {
        close: "Schließen",
        create_item: "Erstelle %{item}",
        move_down: "nach unten bewegen",
        move_up: "Nach oben bewegen",
        select_all: "Wählen Sie Alle",
        select_row: "Wählen Sie diese Zeile aus",
        update: "Update",
      },
      input: {
        select: {
          emptyText: 'Keiner',
        },
      },
      message: {
        bulk_update_content: "Sind Sie sicher, dass Sie diesen %{Name} aktualisieren " +
          "möchten? |||| Sind Sie sicher, dass Sie diese %{smart_count} Elemente " +
          "aktualisieren möchten?",
        bulk_update_title:
          "%{name} aktualisieren |||| Aktualisieren Sie %{smart_count} %{name}",
        error: "Es ist ein unerwarteter Fehler aufgetreten",
        invalid_form: "Eingabefehler, bitte überprüfe Deine Eingaben",
      },
      notification: {
        created: "Element erfolgreich gelöscht",
        item_doesnt_exist: "Es ist ein unerwarteter Fehler aufgetreten",
        not_authorized: "Sie sind nicht berechtigt, auf diese Ressource zuzugreifen.",
        updated: "Änderungen erfolgreich gespeichert",
      },
      page: {
        loading: "Flinkit wird geladen...",
      },
      sort: {
        ASC: "Aufsteigend",
        DESC: "Absteigend",
        sort_by: "Sortieren nach %{field} %{order}",
      },
    },
  }),
  actions: {
    cancel: "Abbrechen",
    confirm: "Bestätigen",
    continue: "Weiter",
    delete: "Löschen",
    discard: "Verwerfen",
    download: "Herunterladen",
    logout: "Abmelden",
    moreInfo: "Mehr Info",
    open: "Öffnen",
    preview: "Vorschau",
    retry: "Erneut versuchen",
    send: "Senden",
    showInactiveFilters: "Filter",
  },
  ages: {
    today: "heute",
    yesterday: "gestern",
  },
  browserNotificationsProvider: {
    enableBrowserNotifications: "Aktiviere Browser-Benachrichtigungen von Flinkit",
    enableBrowserNotificationsButtonText: "Einfach hier klicken",
  },
  chat: {
    autoTranslationSettingButton: {
      autoDetection: "Automatische Erkennung und Übersetzung von eingehenden " +
        "Nachrichten.",
      doNotTranslate: "(Nicht übersetzen)",
      moreSettings: "Zusätzliche Einstellungen",
      subtitle: "Wähle eine Sprache aus, in die alle ausgehenden Nachrichten " +
        "automatisch für Deine Kunden übersetzt werden sollen. Diese Einstellung gilt " +
        "nur für den aktuellen Chat.",
      title: "Übersetzung",
    },
    chatAssignment: {
      unassigned: "Unzugewiesen",
    },
    chatList: {
      withoutText: "(Ohne Text)",
    },
    chatMessageBubble: {
      chatMessageFileAttachmentList: {
        chatMessageFileAttachment: {
          downloadAudioMessage: "<a>Laden Sie die Audiodatei herunter</a>, " +
            "um es zu spielen",
          locationChatMessageFileAttachment: {
            cannotShowGpsPosition: "Es tut uns leid, wir können die GPS-Position " +
              "gerade nicht anzeigen",
          },
          transcribedTextWarning: "Diese Nachricht wurde automatisch transkribiert:",
        },
      },
      forwardedBy: "Weitergeleitet von",
      receivingErrorNotice: {
        generalMessage: "Eine nicht unterstützte WhatsApp-Nachricht wurde an Sie " +
          "gesendet",
      },
      repliedChatMessageBanner: {
        repliedMessageAuthoredByTheMerchantUserReading: "Du",
      },
      translationBanner: {
        translatedToDetectedLanguage: "%{language} automatisch übersetzt",
        translatedToTranslatedLanguages: "Automatisch übersetzt auf %{languages}",
      },
      unknownWhatsappMessageTypeText: "Der Nutzer hat möglicherweise versucht, ein " +
        "Dokument und einen Text in derselben Nachricht zu senden. Das Dokument und " +
        "der Text müssen in zwei separaten Nachrichten gesendet werden.",
      whatsappMessageStatus: {
        error: {
          couldNotReceiveMessage: "Nachricht konnte nicht empfangen werden " +
            "(Fehlercode: %{errorCode})",
          failedToSend: "Senden der Nachricht fehlgeschlagen (Fehlercode: %{errorCode})",
          failedToSendWithoutErrorCode: "Senden der Nachricht fehlgeschlagen",
          messageCouldNotBeCanceledOrSent: "Nachricht konnte nicht abgebrochen werden " +
            "oder gesendet",
        },
      },
    },
    chatMessageList: {
      index: {
        forward: "Weiterleiten an Teamchat",
        noText: "(Ohne Text)",
      },
    },
    external: {
      channelSettingsButtonLabel: "Einstellungen",
      contextMenu: {
        addContact: "Kontakt hinzufügen",
        addFlag: "Chat markieren",
        archive: "Chat erledigt",
        editContact: "Kontakt bearbeiten",
        removeFlag: "Markierung entfernen",
        spam: {
          confirm: "Bist Du sicher, dass Du diese Nummer blockieren möchtest? Du " +
            "kannst von dieser Nummer keine Nachrichten mehr empfangen",
          success: "Die Nummer wurde erfolgreich blockiert",
          title: "Nummer blockieren",
        },
        unarchive: "Chat wieder öffnen",
        unblock: "Nummer entsperren",
      },
      emptyChatNotification: {
        magicTemplate: "Zum Start einer Konversation wird deine Nachricht automatisch " +
          "als Template gesendet. Das kann bis zu 30 Minuten dauern.",
        normalTemplate: "Zum Start der Konversation kannst Du eine vorbereitete " +
          "<template>Template-Nachricht</template> senden.",
      },
      fileAttachment: "Dateianhang",
      filters: {
        all: "Alle",
        archived: "Erledigt",
        mine: "Meine",
        unassigned: "Nicht zugewiesen",
      },
      header: {
        merchantCustomerUserSubtitle: {
          customerCodePrefix: "Kunden-Nr.",
        },
        saveContact: "Kontakt speichern",
      },
      templateMessage: "Template-Nachricht",
      title: "Eingang",
      toolbar: {
        audioRecorderTimeLimit: "Aufnahme pausiert, Sie können nur Sprachnachrichten " +
          "mit einer Länge von bis zu 5 Minuten aufnehmen",
        expiredReplyWindowNotification: {
          message: {
            magicTemplate: "Du bist außerhalb des 24-Stunden-Zeitraums, daher " +
              "wird deine Nachricht automatisch als Template gesendet. Das kann bis zu " +
              "30 Minuten dauern.",
            normalTemplate: "Das 24-Stunden-Antwortfenster ist abgelaufen. Du hast " +
              "jetzt die Möglichkeit, eine vorbereitete " +
              "<template>Template-Nachricht</template> oder einen " +
              "<file>Dateianhang</file> " +
              "zu versenden.",
          },
        },
      },
    },
    internal: {
      contextMenu: {
        archive: "Chat archivieren",
        editChat: "Chat bearbeiten",
        unarchive: "Chat wieder öffnen",
      },
      filters: {
        all: "Alle",
        archived: "Archiviert",
      },
      productNewsChatTitle: "Flinkit News 🗞",
      title: "Teamchat",
    },
    toolbar: {
      chatMessageTextFieldPlaceholder: "Ihre Nachricht",
      emojiPickerButton: {
        recent: "Aktuelle",
        search: "Suche",
        searchResults: "Suchergebnisse",
        title: "Emojis",
      },
      fileAttachment: {
        draggedFileRejected: "Datei kann nicht angehängt werden!",
        selectFromFiles: "Wählen Sie aus Dateien aus",
        selectFromGallery: "Aus der Galerie auswählen",
      },
      translationPopper: {
        translatedInto: "Übersetzt in %{language}%{loadingDots}",
      },
    },
  },
  chat_message_template_placeholder_types: {
    FREE_TEXT: "Freitext",
    MERCHANT_CUSTOMER_USER_COMPANY_NAME: "Firma",
    MERCHANT_CUSTOMER_USER_FIRST_NAME: "Vorname",
    MERCHANT_CUSTOMER_USER_LAST_NAME: "Nachname",
    MERCHANT_CUSTOMER_USER_RESPONSIBLE_MERCHANT_USER_NAME: "Ansprechpartner",
  },
  chat_messages: {
    receivingErrorCode: {
      UNSUPPORTED_FILE_ATTACHMENT_MIME_TYPE: "Diese Nachricht enthält eine nicht " +
        "unterstützte angehängte Datei",
      WHATSAPP_MEDIA_UNDOWNLOADABLE: "Diese Nachricht enthält eine angehängte Datei, " +
        "die nicht heruntergeladen werden konnte",
    },
  },
  chatMessageTemplateBuilder: {
    templatePlaceholder: {
      errors: {
        labelRequired: "%{formattedLabel} muss ausgefüllt werden",
        maxLabelLength: "%{formattedLabel} darf %{maxLength} Zeichen nicht überschreiten",
      },
    },
  },
  chatMessageTemplateEditor: {
    freeText: "Freitext",
    insertBlockButton: "Baustein einfügen",
    paperPreview: {
      button: "Button",
      links: "Links",
    },
    templateInput: {
      addButtonUrlSwitchLabel: "Button hinzufügen",
      addHeadLineSwitchLabel: "Überschrift hinzufügen",
      addHeaderMediaFileSwitchLabel: "Bild hinzufügen",
      buttonLinkInputPlaceholder: "Link einfügen",
      buttonTextInputPlaceholder: "Button-Titel",
      headerMediaFilePickerDefaultText: "Bild auswählen",
      headlinePlaceholder: "Überschrift hier einfügen",
      textInputPlaceholder: "Text hier eingeben",
    },
  },
  dialogs: {
    appUpdateAvailable: {
      button: "Jetzt App-Update installieren",
      message: "Eine neuere App-Version ist verfügbar. Bitte installiere zunächst das " +
        "Update um Flinkit ohne Einschränkungen nutzen zu können.",
    },
    channelSettings: {
      autoReplies: {
        absence: {
          endDate: "Enddatum",
          expired: "Abwesenheit abgelaufen",
          initialValue: "Hallo, ich habe deine Nachricht erhalten. Ich bin derzeit " +
            "abwesend, werde aber sofort nach meiner Rückkehr antworten.",
          invalidDatesOrder: "Enddatum kann nicht früher als Startdatum sein",
          invalidEndDate: "Enddatum erforderlich",
          invalidStartDate: "Startdatum erforderlich",
          startDate: "Startdatum",
          textInputLabel: "Dein Text",
          title: "Abwesend",
          toggleLabel: "Sende eine automatische Antwort, wenn sich dein Kunde bei dir " +
            "meldet, während du nicht erreichbar bist.",
        },
        birthdayMessage: {
          missingTemplateError: "Bitte wähle ein Template aus",
          title: "Automatisierte Geburtstagsnachricht",
          toggleLabel: "Diese Nachricht wird an jeden Kontakt gesendet der einen " +
            "Geburtstag eingetragen hat.",
        },
        marketingOptIn: {
          title: "Marketing-Opt-in-Chatbot",
          toggleLabel: "Mit diesem Chatbot kannst Du die Zustimmung Deiner Kunden " +
            "einholen um Kampagnennachrichten zu empfangen",

        },
        noChatBotSetup: "Für diesen Kanal ist bisher kein Chatbot eingerichtet. Um " +
          "die Einrichtung zu starten schreibe einfach eine Mail an ",
        outsideWorkingHours: {
          initialValue: "Hallo und herzlich willkommen. Du erreichst uns außerhalb " +
            "unserer Geschäftszeiten. Wir melden uns schnellstmöglich bei Dir!",
          textInputLabel: "Dein Text",
          title: "Außerhalb der Öffnungszeiten",
          toggleLabel: "Sende Deinem Kunden eine automatische Antwort, wenn er " +
            "versucht, Dich außerhalb der Öffnungszeiten zu erreichen.",
        },
        permissionDenied: "Um Änderungen an den automatischen Antworten vorzunehmen, " +
          "wende Dich bitte an Deinen Admin.",
        title: "Auto-Antworten",
        welcomeMessage: {
          initialValue: "Guten Tag, deine Anfrage ist bei uns eingegangen und wir " +
            "werden uns schnellstmöglich bei dir zurückmelden. Viele Grüße.",
          textInputLabel: "Dein Text",
          title: "Willkommensnachricht",
          toggleLabel: "Sende Deinem Kunden eine automatische Willkommensnachricht " +
            "sobald er sich zum ersten Mal bei Dir meldet und du ihm bisher keine " +
            "Nachricht gesendet hast.",
        },
      },
      general: {
        membersListTitle: "Mitglieder",
        qrCode: {
          info: "Wenn deine Kunden diesen QR-Code scannen, gelangen sie direkt " +
            "zu diesem WhatsApp-Kanal. Teile ihn gerne mit ihnen.",
          shareViaEmailBody: "Klicken Sie auf diesen Link, landet man direkt in diesem " +
            "WhatsApp-Kanal%0D%0A" +
            "- teile ihn mit deinen Kunden%0D%0A" +
            "%{whatsappUrl}%0D%0A",
          title: "QR Code",
        },
        title: "Allgemein",
        whatsappAccountDisconnectionAlert: {
          connectAction: "Verbinde es mit Flinkit",
          currentConnection: "Derzeit verbunden mit: %{webhookHostname}",
          errorMessages: {
            INVALID_WHATSAPP_ACCOUNT: "Ungültiger Whatsapp-Account",
          },
          message: {
            disconnected: "Dein WhatsApp-Konto ist aktuell nicht mit Flinkit " +
              "verbunden und kann deshalb weder Nachrichten versenden noch empfangen",
            notReady: "Ihr WhatsApp-Konto ist noch nicht bereit und kann daher keine " +
              "Nachrichten senden oder empfangen",
          },
          successMessages: {
            connected: "Das WhatsApp-Konto wurde erfolgreich verbunden",
          },
        },
      },
      title: "Einstellungen",
      whatsappAccountBusinessProfile: {
        title: "WhatsApp-Profil",
      },
      workingHours: {
        closed: "Geschlossen",
        forbidden: "Um Änderungen an den Öffnungszeiten vorzunehmen, wende Dich" +
          "bitte an Deinen Admin.",
        from: "Von",
        subtitle: "Trage Deine Öffnungszeiten ein, damit Deine Kunden wissen, wann Du " +
          "erreichbar bist.",
        title: "Öffnungszeiten",
        to: "Bis",
      },
    },
    chatEdit: {
      manageMembersTitle: "Chat-Mitglieder hinzufügen oder entfernen",
      renameInputPlaceholder: "Chat-Name",
      searchUsersInputPlaceholder: "Suche mit Name",
      title: "Chat bearbeiten",
    },
    profile: {
      companySettings: {
        addMerchantUser: {
          title: "Nutzer hinzufügen",
        },
        channelsAndUsers: {
          addAdmins: "Add admins",
          addUsers: "Add users",
          admins: "Admins",
          apply: "Übernehmen",
          removeAll: "Alle entfernen",
          title: "Kanal & User Verwaltung",
          users: "Users",
        },
        createNumber: {
          emptyList: "Keine WhatsApp-Konten",
          numbersAlreadyCreated: "Bereits erstellte Nummern:",
          title: "Nummer anlegen",
          whatsappConnectionButton: "Neue WhatsApp-Nummern über %{provider} verbinden",
        },
        faq: {
          linkText: "Finde hier",
          text: "Hilfe und Antworten auf häufig gestellte Fragen zur Einrichtung von " +
            "Flinkit.",
        },
        location: {
          subtitle: "Jeder Standort hat seine eigene User- Kontakt-Datenbank",
          title: "Weiteren Standort erstellen",
        },
        title: "Firmen Einstellungen",
      },
      merchantUsersSettings: {
        accountDeletionButton: "Konto löschen",
        accountDeletionButtonError: "Fehler: %{message}",
        accountDeletionConfirm: "Möchtest Du Dein Nutzerkonto wirklich löschen?",
        thirdPartyConnections: {
          buttons: {
            hubspot: {
              synchronize: "Mit Hubspot synchronisieren",
            },
            microsoftDynamics: {
              synchronize: "Mit Microsoft Dynamics synchronisieren",
            },
            salesforce: {
              synchronize: "Mit Salesforce synchronisieren",
              synchronized: "Mit Salesforce synchronisiert",
            },
            sap: {
              synchronize: "Mit SAP synchronisieren",
            },
          },
          title: "Anbindungen",
        },
        title: "Profil Details",
        updateEmailAddress: {
          title: "Aktualisiere Email Adresse",
        },
      },
      sandbox: {
        title: "Start-Optionen",
      },
      title: "Profil",
    },
    startChat: {
      external: {
        inputPlaceholder: "Name oder Mobilfunknummer eingeben",
        subtitle: "Kontakt auswählen",
        title: "Chat öffnen",
      },
      internal: {
        actionButtonText: "Chat eröffnen",
        form: {
          chatName: {
            label: "Chat-Name",
          },
          teamMembers: {
            label: "Mitarbeiter wählen",
            placeholder: "Kollege",
          },
        },
        title: "Teamchat eröffnen",
      },
    },
    templateMessageComposer: {
      subtitle: "Templates sind bereits vorbereitete Nachrichten, die du jederzeit " +
        "versenden kannst.",
      templateBuilder: {
        chooseTemplate: "Template wählen",
        fillPlaceholder: "Platzhalter ausfüllen",
      },
      title: "Template Nachrichten",
    },
    thirdPartyConnection: {
      failed: "Verbindung fehlgeschlagen",
      loading: "Verbindung wird abgeschlossen...",
      success: "Verbindung erfolgreich",
    },
  },
  errors: {
    codes: {
      EMAIL_ADDRESS_NOT_FOUND: "Die E-Mail-Adresse wurde nicht gefunden",
    },
    unknown: "Es ist ein unbekannter Fehler aufgetreten.",
  },
  extendedList: {
    selectAllPages: "Wählen Sie alle Seiten aus",
  },
  hooks: {
    useBrowserNotifications: {
      notificationTitle: "Neue Benachrichtigung",
    },
    useTakePicture: {
      promptLabelCancel: "Abbrechen",
      promptLabelHeader: "Bild",
      promptLabelPhoto: "Von Fotos",
      promptLabelPicture: "Von Kamera",
    },
    useUserDisplayName: {
      channelChatBotUser: "🤖 Automatische Antwort",
    },
  },
  languages: {
    // Extracted from https://de.wikipedia.org/w/index.php?title=Liste_der_ISO-639-1-Codes
    'aa': "Afar",
    'ab': "Abchasisch",
    'ae': "Avestisch",
    'af': "Afrikaans",
    'ak': "Akan",
    'am': "Amharisch",
    'an': "Aragonesisch",
    'ar': "Arabisch",
    'as': "Assamesisch",
    'av': "Avarisch",
    'ay': "Aymara",
    'az': "Aserbaidschanisch",
    'ba': "Baschkirisch",
    'be': "Belarussisch",
    'bg': "Bulgarisch",
    'bh': "Bihari",
    'bi': "Bislama",
    'bm': "Bambara",
    'bn': "Bengalisch",
    'bo': "Tibetisch",
    'br': "Bretonisch",
    'bs': "Bosnisch",
    'ca': "Katalanisch",
    'ce': "Tschetschenisch",
    'ch': "Chamorro",
    'co': "Korsisch",
    'cr': "Cree",
    'cs': "Tschechisch",
    'cu': "Kirchenslawisch",
    'cv': "Tschuwaschisch",
    'cy': "Walisisch",
    'da': "Dänisch",
    'de': "Deutsch",
    'dv': "Dhivehi",
    'dz': "Dzongkha",
    'ee': "Ewe",
    'el': "Griechisch",
    'en': "Englisch",
    'eo': "Esperanto",
    'es': "Spanisch",
    'et': "Estnisch",
    'eu': "Baskisch",
    'fa': "Persisch",
    'ff': "Fulfulde",
    'fi': "Finnisch",
    'fj': "Fidschi",
    'fo': "Färöisch",
    'fr': "Französisch",
    'fy': "Westfriesisch",
    'ga': "Irisch",
    'gd': "Schottisch-gälisch",
    'gl': "Galicisch",
    'gn': "Guaraní",
    'gu': "Gujarati",
    'gv': "Manx-Gälisch",
    'ha': "Hausa",
    'he': "Hebräisch",
    'hi': "Hindi",
    'ho': "Hiri Motu",
    'hr': "Kroatisch",
    'ht': "Haitianisch",
    'hu': "Ungarisch",
    'hy': "Armenisch",
    'hz': "Otjiherero",
    'ia': "Interlingua",
    'id': "Indonesisch",
    'ie': "Interlingue",
    'ig': "Igbo",
    'ii': "Yi",
    'ik': "Inupiaq",
    'io': "Ido",
    'is': "Isländisch",
    'it': "Italienisch",
    'iu': "Inuktitut",
    'ja': "Japanisch",
    'jv': "Javanisch",
    'ka': "Georgisch",
    'kg': "Kikongo",
    'ki': "Kikuyu",
    'kj': "oshiKwanyama",
    'kk': "Kasachisch",
    'kl': "Grönländisch",
    'km': "Khmer",
    'kn': "Kannada",
    'ko': "Koreanisch",
    'kr': "Kanuri",
    'ks': "Kashmiri",
    'ku': "Kurdisch",
    'kv': "Komi",
    'kw': "Kornisch",
    'ky': "Kirgisisch",
    'la': "Latein",
    'lb': "Luxemburgisch",
    'lg': "Luganda",
    'li': "Limburgisch",
    'ln': "Lingála",
    'lo': "Laotisch",
    'lt': "Litauisch",
    'lu': "Kiluba",
    'lv': "Lettisch",
    'mg': "Malagassi",
    'mh': "Marshallesisch",
    'mi': "Maori",
    'mk': "Mazedonisch",
    'ml': "Malayalam",
    'mn': "Mongolisch",
    'mr': "Marathi",
    'ms': "Malaiisch",
    'mt': "Maltesisch",
    'my': "Birmanisch",
    'na': "Nauruisch",
    'nb': "Bokmål",
    'nd': "Nord-Ndebele",
    'ne': "Nepali",
    'ng': "Ndonga",
    'nl': "Niederländisch",
    'nn': "Nynorsk",
    'no': "Norwegisch",
    'nr': "Süd-Ndebele",
    'nv': "Navajo",
    'ny': "Chichewa",
    'oc': "Okzitanisch",
    'oj': "Ojibwe",
    'om': "Oromo",
    'or': "Oriya",
    'os': "Ossetisch",
    'pa': "Panjabi",
    'pi': "Pali",
    'pl': "Polnisch",
    'ps': "Paschtunisch",
    'pt': "Portugiesisch",
    'qu': "Quechua",
    'rm': "Romanisch",
    'rn': "Kirundi",
    'ro': "Rumänisch",
    'ru': "Russisch",
    'rw': "Ruandisch",
    'sa': "Sanskrit",
    'sc': "Sardisch",
    'sd': "Sindhi",
    'se': "Nordsamisch",
    'sg': "Sango",
    'sh': "Serbokroatisch",
    'si': "Singhalesisch",
    'sk': "Slowakisch",
    'sl': "Slowenisch",
    'sm': "Samoanisch",
    'sn': "Shona",
    'so': "Somali",
    'sq': "Albanisch",
    'sr': "Serbisch",
    'ss': "Siswati",
    'st': "Sesotho",
    'su': "Sundanesisch",
    'sv': "Schwedisch",
    'sw': "Swahili",
    'ta': "Tamil",
    'te': "Telugu",
    'tg': "Tadschikisch",
    'th': "Thai",
    'ti': "Tigrinya",
    'tk': "Turkmenisch",
    'tl': "Tagalog",
    'tn': "Setswana",
    'to': "Tongaisch",
    'tr': "Türkisch",
    'ts': "Xitsonga",
    'tt': "Tatarisch",
    'tw': "Twi",
    'ty': "Tahitisch",
    'ug': "Uigurisch",
    'uk': "Ukrainisch",
    'ur': "Urdu",
    'uz': "Usbekisch",
    've': "Tshivenda",
    'vi': "Vietnamesisch",
    'vo': "Volapük",
    'wa': "Wallonisch",
    'wo': "Wolof",
    'xh': "isiXhosa",
    'yi': "Jiddisch",
    'yo': "Yoruba",
    'za': "Zhuang",
    'zh': "Chinesisch",
    'zu': "Zulu",
  },
  pages: {
    companyDetails: {
      button: "Jetzt loslegen",
      inputs: {
        companyName: {
          label: "Unternehmen",
          placeholder: "Unternehmensname",
        },
        companySize: {
          label: "Größe",
          options: {
            '1-10': "1 bis 10 Mitarbeiter",
            '11-50': "11 bis 50 Mitarbeiter",
            '51-200': "51 bis 200 Mitarbeiter",
            '201-500': "201 bis 500 Mitarbeiter",
            '500+': "Mehr als 500 Mitarbeiter",
          },
          placeholder: "Unternehmensgröße wählen",
        },
        industry: {
          label: "Branche",
          options: {
            AUTOMOTIVE: "Automobil",
            CONSTRUCTION: "Bauwesen",
            DIGITAL_AGENCY: "Digitale Agentur",
            EDUCATION_SERVICES: "Bildungsdienstleistungen",
            GOVERNMENT: "Regierung und öffentlicher Sektor",
            HEALTHCARE: "Gesundheitswesen",
            HOTELS: "Hotel, Restaurants & Freizeit",
            HOUSEHOLD: "Haushaltswaren & Dienstleistungen",
            INSURANCE: "Versicherung",
            ONLINE_MARKETPLACES: "Online-Marktplätze & SaaS-Plattformen",
            OTHER: "Andere",
            PROFESSIONAL_SERVICES: "Professionelle Dienstleistungen",
          },
          placeholder: "Branche auswählen",
        },
      },
      subtitle: "Vervollständige deine Angaben, um loszulegen.",
      title: "🎉 Konto erfolgreich erstellt",
      trustedCompaniesTitle: "Führende Unternehmen setzen auf Flinkit",
    },
    login: {
      button: "Einloggen",
      errors: {
        UNAUTHORIZED_USER: "Ungültige E-Mail-Adresse und/oder Passwort",
        UNEXPECTED_AUTH_ERROR: "Unerwarteter Authentifizierungsfehler",
      },
      inputs: {
        emailAddress: {
          label: "Email-Adresse",
          placeholder: "johndoe@doe.de",
        },
        password: {
          label: "Passwort",
          placeholder: "Geben Sie Ihr Passwort ein",
        },
      },
      links: {
        forgotPassword: "Passwort vergessen? <forgot>Zurücksetzen</forgot>",
        signup: "Noch kein Konto? <signup>Registriere dich jetzt</signup>",
      },
      title: "👋 Willkommen zurück",
    },
    notFound: {
      message: "Entschuldigung, wir können die von Ihnen gesuchte Seite nicht finden!",
      title: "Seite nicht gefunden",
    },
    requestPasswordReset: {
      inputs: {
        emailAddress: {
          label: "Email-Adresse",
          placeholder: "johndoe@doe.de",
        },
      },
      login: "Zurück zum <login>Einloggen</login>",
      register: "Noch kein Konto? <register>Registriere dich jetzt.</register>",
      resetRequestSent: {
        login: "Zurück zum Login",
        resend: "Nicht da? <link>E-Mail erneut senden</link>",
        subtitle: "Eine E-Mail mit Anweisungen wurde an deinen Posteingang gesendet.",
        title: "📬 Überprüfe deinen E-Mail-Posteingang",
        warn: "Falls du keine E-Mail erhältst, überprüfe bitte deinen Spam-Ordner.",
      },
      submit: "Zurücksetzen",
      title: "Passwort zurücksetzen",
    },
    resetPassword: {
      errors: {
        INVALID_PASSWORD_RESET_CODE: "Die Anfrage zum Zurücksetzen des Passworts ist " +
          "abgelaufen. Bitte fordern Sie ein neues Zurücksetzen des Passworts an",
      },
      fields: {
        newPassword: {
          label: "Neues Passwort",
          placeholder: "Geben Sie mindestens 8 Zeichen ein",
        },
        repeatNewPassword: {
          label: "Geben Sie das neue Passwort erneut ein",
          placeholder: "Geben Sie mindestens 8 Zeichen ein",
        },
      },
      login: "Erinnern Sie sich an Ihr Passwort? <login>Einloggen</login>",
      passwordRequirements: {
        digit: "Mindestens eine Nummer",
        length: "Mindestens 8 Zeichen",
        lowercase: "Mindestens ein Kleinbuchstabe",
        uppercase: "Mindestens ein Großbuchstabe",
      },
      passwordResetSuccess: {
        login: "Zurück zum Login",
        nextStep: "Verwende dein neues Passwort, um dich einzuloggen",
        subtitle: "Dein Passwort wurde erfolgreich geändert.",
        title: "🎉 Passwort erfolgreich geändert",
      },
      register: "Noch kein Konto? <register>Registriere dich jetzt.</register>",
      title: "Neues Passwort eingeben",
      validations: {
        newPasswordRepeat: "Passwort und Passwortbestätigung stimmen nicht überein",
      },
    },
    salesforceAuthentication: {
      connectionError: "Dein Salesforce-Konto konnte noch nicht mit Flinkit verbunden " +
        "werden",
      connectionSuccess: "Dein Salesforce-Konto ist nun mit Flinkit verbunden",
    },
    sandbox: {
      subtitle: "Wie möchtest du loslegen?",
      title: "👋 Willkommen %{firstName}",
    },
    signup: {
      button: "Kostenloses Konto erstellen",
      errors: {
        MERCHANT_USER_EXISTS: "Ein anderer Benutzer mit dieser " +
          "E-Mail-Adresse existiert bereits",
      },
      inputs: {
        emailAddress: {
          label: "Geschäfts-E-Mail Adresse",
          placeholder: "mustermann@acme.de",
        },
        fullName: {
          label: "Vor- und Nachname",
          placeholder: "z.B. Max Mustermann",
        },
        password: {
          label: "Passwort",
          placeholder: "Gib mindestens 8 Zeichen ein.",
        },
      },
      links: {
        forgotPassword: "Passwort vergessen? <forgot>Zurücksetzen.</forgot>",
        login: "Bereits ein Konto? <login>Einloggen</login>",
      },
      title: "Kostenloses Konto erstellen",
    },
    startExternalChat: {
      embeddedExternalChatPage: {
        openInFlinkit: "In Flinkit öffnen",
      },
      selectChannel: "Bitte wählen Sie einen Kanal:",
    },
    whatsappChannelsConnection: {
      connectionError: "Dein WhatsApp-Konto konnte noch nicht verbunden werden",
      connectionSuccess: "Dein WhatsApp-Konto ist nun mit Flinkit verbunden",
      errorCodes: {
        CHANNEL_PERMISSION_NOT_GRANTED: "Fehlende Berechtigung. Bitte gewähren Sie " +
          "Flinkit die Berechtigung zur Verwaltung der folgenden Kanäle: " +
          "<strong>%{channelIdentifiers}</strong>",
        CONNECTING_ON_PREMISE_NUMBER_UNSUPPORTED: "Die folgenden Kanäle werden noch " +
          "lokal gehostet, was derzeit nicht unterstützt wird. Bitte migrieren Sie zur " +
          "Cloud-API-Hosting: <strong>%{channelIdentifiers}</strong>",
        THREE_SIXTY_CHANNEL_METADATA_ERROR: "Es kann keine Verbindung zum " +
          "WhatsApp-Konto hergestellt werden, da die relevanten Kanalmetadaten von " +
          "360dialog für channel_identifiers=<strong>%{channelIdentifiers}</strong> " +
          "nicht abgerufen werden konnten.",
        THREE_SIXTY_CHANNEL_NOT_FOUND: "Die folgenden Kanäle wurden auf 360dialog " +
          "nicht gefunden: <strong>%{channelIdentifiers}</strong>",
        UNEXPECTED: "Aus einem unerwarteten Grund konnten die Kanäle nicht verbunden " +
          "werden. Bitte versuchen Sie es erneut: <strong>%{channelIdentifiers}</strong>",
      },
    },
  },
  resources: {
    aggregated_merchant_customer_user_tags: {
      fields: {
        tagName: "Tag",
      },
    },
    campaigns: {
      action: {
        create: "Neue Kampagne",
      },
      audienceTypeChoices: {
        ALL: "Alle Kontakte",
        ALL_WITH_MARKETING_OPT_IN: "Alle Kontakte mit Opt-in",
        SELECTED_CUSTOMER_USERS: "Einzelauswahl",
      },
      create: {
        createAction: "Weiter",
        error: "Fehler beim Erstellen der Kampagne",
        inputs: {
          audienceType: {
            subtitle: "An welchen Kundenkreis soll diese Kampagne gesendet werden?",
          },
          channelId: {
            label: "Kanal",
            subtitle: "Von welchem Kanal aus soll diese Kampagne versendet werden",
          },
          chatMessageTemplateId: {
            label: "Wähle ein Template",
            subtitle: "Um eine Kampagne erstellen zu können benötigst du ein " +
              "freigegebenes Kampagnen Template",
          },
          name: {
            subtitle: "Dieser Name dient ausschließlich internen Zwecken und wird dem " +
              "Kunden nicht angezeigt.",
          },
          scheduledTimestamp: {
            label: "Datum und Uhrzeit",
            subtitle: "Lege das Datum und die Uhrzeit fest, zu der die Kampagne " +
              "geschaltet werden soll",
          },
        },
        success: "Kampagne erfolgreich erstellt",
        toolbar: {
          confirmDialog: {
            emptyContacts: "Noch kein Kontakt ausgewählt",
            instantCampaignMessage: "Deine Kampagne mit dem Namen " +
              "“<strong>%{name}</strong>“ wird “<strong>sofort</strong>“ an diese " +
              "Kontakte gesendet.",
            scheduledCampaignMessage: "Deine Kampagne mit dem Namen " +
              "“<strong>%{name}</strong>“ wird " +
              "“<strong>am %{scheduledDatetime}</strong>“ an diese Kontakte gesendet.",
            termsAndConditions: "Durch das Senden bestätigst Du, dass Du die " +
              "Bedingungen für den WhatsApp-Newsletter-Versand zur Kenntnis genommen " +
              "hast.",
            title: "Kampagne senden",
          },
        },
      },
      createFormTitle: "Kampagnen erstellen",
      createTemplate: "Neues Template anlegen",
      createTemplateDialog: {
        confirmationText: "Weiter zu Templates",
        content: "Du wirst zur Template-Erstellung weitergeleitet, deine aktuelle " +
          "Kampagne wird nicht gespeichert.",
      },
      empty: "Du hast bisher noch keine Kampagnen erstellt",
      fields: {
        audienceType: "Kunden-Zielgruppe",
        channelId: "Kampagnen-Kanal",
        chatMessageTemplateId: "Kampagnen-Template",
        deliveredMessageCount: "Geliefert an",
        insertionTimestamp: "Erstellt um",
        insertionUserId: "Gesendet von",
        name: "Name der Kampagne",
        readMessageCount: "Gelesen von",
        scheduledTimestamp: "Zeitplan",
        sentTimestamp: "Gesendet",
        totalMessageCount: "Gesendet an",
      },
      invite: "Starte doch jetzte eine neue Kampagne!",
      list: {
        description: "Über eine Kampagne hast du die Möglichkeit, mehreren Kontakte " +
          "gleichzeitig eine Nachricht zu schreiben, um beispielsweise Newsletter und " +
          "Informationen zu neuen Produkten & Angeboten zu versenden.",
        description2: "Um eine Kampagne zu starten benötigst du ein freigegebenes " +
          "Kampagnen-Template.",
      },
      name: "Kampagnen",
      show: {
        campaignPerformanceSubtitle:
          "Die Prozentzahl zeigt das Verhältnis von Stufe zu Stufe.",
        campaignPerformanceTitle: "Kampagnen-Performance",
        confirmDelete: "Sind Sie sicher, dass Sie diese Kampagne löschen möchten?",
        exportMessageMetadata: {
          errors: {
            ONGOING_CAMPAIGN: "Informationen zur Kampagnennachricht können nicht " +
              "exportiert werden, da diese Kampagne noch läuft. Bitte warten Sie, bis " +
              "es fertig ist.",
            PENDING_CAMPAIGN: "Informationen zur Kampagnennachricht können nicht " +
              "exportiert werden, da diese Kampagne noch nicht gestartet wurde.",
          },
        },
        fields: {
          audienceType: "Gesendet an:",
          scheduledFor: "Geplant für",
          sentOn: "Gesendet am",
        },
        templatePreview: {
          label: "Benutztes Template",
        },
      },
    },
    channel_chat_bot_users: {
      fields: {
        optInTriggerAutoresponse: "Opt-In Nachricht",
        optOutTrigger: "Opt-Out Trigger",
        optOutTriggerAutoresponse: "Opt-Out Bestätigungsantwort",
      },
      title: "Opt-In Chatbot-Einstellungen",
    },
    channels: {
      edit: {
        inputs: {
          name: {
            label: "Kanal (%{phoneNumber})",
            subtitle: "Name dieses Kanals",
          },
        },
      },
      fields: {
        birthdayMessageTemplateId: "Template",
      },
    },
    chat_message_templates: {
      action: {
        create: "Neues Template",
        delete: "Template löschen",
      },
      confirmDeletion: "Möchtest Du dieses Template wirklich löschen?",
      create: {
        createButton: "Template einreichen",
        error: "Fehler beim Erstellen des Templates",
        freeTextNotAllowedInCampaignTemplate: "'{{Freitext}}' Bausteine werden in " +
          "einem Marketing Template nicht unterstützt",
        imageRequiredForHeaderMediaSwitch: "Falls der Schalter „Bild hinzufügen“ " +
          "aktiviert ist, muss ein Bild definiert werden",
        inputs: {
          name: {
            label: "Template Name",
          },
          type: {
            label: "Auswählen",
          },
        },
        nameSubtitle: "Dieser Name dient ausschließlich internen Zwecken und wird dem " +
          "Kunden nicht angezeigt.",
        success: "Template erfolgreich erstellt",
        title: "Template erstellen",
        typeSubtitle: "Kampagnen Templates sind ausschließlich für Marketing Maßnahmen " +
          "vorgesehen",
      },
      createFormTitle: "Template erstellen",
      description: "Durch individuelle Templates kannst Du flexibel und bequem mit " +
        "Deinen Kunden kommunizieren.",
      empty: "Noch keine Templates vorhanden",
      errorMessages: {
        INVALID_PLACEHOLDER_NAME: "Mindestens ein Platzhalter hat einen ungültigen Namen",
        TEMPLATE_ALREADY_EXISTS:
          "Ein anderes Template existiert bereits mit diesem Namen",
      },
      fields: {
        approvalStatus: "Freigabe",
        category: "Art",
        language: "Sprache",
        name: "Name",
        status: "Status",
        text: "Nachricht",
        type: "Art",
      },
      invite: "Neues erstellen",
      name: "Templates",
      startCampaign: "Neue starten",
      types: {
        MARKETING: "Kampagne",
        UTILITY: "Standard",
      },
    },
    chats: {
      empty: "Dein Posteingang ist leer",
      invite: "Starte doch jetzt einen Chat mit Deinen Kunden!",
    },
    customer_users: {
      fields: {
        whatsappDisplayName: "WhatsApp-Name",
        whatsappPhoneNumber: "Telefonnummer",
      },
    },
    merchant_customer_users: {
      action: {
        create: "Neuen Kontakt anlegen",
        seeChat: "Zum Chat",
      },
      create: {
        company: "Firma",
        error: "Fehler beim Erstellen des Kontakts",
        inputs: {
          whatsappPhoneNumber: {
            helperText: "Format: +493028874362",
          },
        },
        internal: "Intern",
        personalData: "Persönliche Daten",
        success: "Neuer Kontakt erfolgreich erstellt",
      },
      createFormTitle: "Kontakt anlegen",
      edit: {
        addTag: "Tag hinzufügen",
        channelTags: "Kanal Tags",
        company: "Firma",
        confirmDeletion: "Möchtest Du diesen Kontakt wirklich löschen?",
        createTagPopupButton: "Neuen Tag erstellen",
        emptyChannelTags: "Bisher ohne Kanal-Tags",
        internal: "Intern",
        personalData: "Persönliche Daten",
        title: "Kontaktdetails",
      },
      empty: "Keinen Kontakt gefunden",
      errorMessages: {
        chatLink: "Der Chat konnte nicht erstellt werden",
      },
      fields: {
        birthdayDate: "Geburtsdatum",
        companyCity: "Stadt",
        companyHouseNumber: "Hausnummer",
        companyName: "Firma",
        companyStreet: "Straße",
        companyZipCode: "Postleitzahl",
        customerCode: "Kundennummer",
        emailAddress: "E-Mail-Adresse",
        firstName: "Vorname",
        isMarketingConsentGranted: "Marketing Opt-In",
        lastName: "Nachname",
        name: "Name",
        optInOptions: {
          open: "Offen",
        },
        phoneNumber: "Telefonnummer (sonstige)",
        primaryResponsibleMerchantUserId: "Ansprechpartner",
        role: "Position im Unternehmen",
        trade: "Branche",
        whatsappPhoneNumber: "Telefonnummer",
      },
      import: {
        button: "Importieren",
        confirm:
          "Sollen alle Kontakte aus dieser Datei \"%{filename}\" importiert werden?",
        error: "Die Kontakte-Datei konnte leider nicht importiert werden. Bitte wenden " +
          "Sie sich an unseren Kundenservice unter support@flinkit.de. Fehlerursache: " +
          "%{message}",
        progressDialog: {
          progressMessage: "Ihre Kontakte-Datei wird importiert. Bitte geben Sie uns " +
            "einen kurzen Moment...",
          title: "Kontakte importieren",
        },
        status: {
          ignored: "%{invalidRowsCount} Zeilen wurden aufgrund folgender " +
            "Ursachen ignoriert:",
        },
        success: "%{importedMerchantCustomerUsersCount} Kontakte wurden erfolgreich " +
          "importiert oder aktualisiert.",
      },
      importRowFailureReasons: {
        EMAIL_ADDRESS_INVALID: "Die E-Mail-Adresse ist keine gültige Adresse",
        FIRST_NAME_MISSING: "Zeile hat keinen Vornamen",
        PHONE_NUMBER_DUPLICATED: "Zeile hat eine doppelte Telefonnummer",
        PHONE_NUMBER_INVALID: "Zeile hat eine ungültige Telefonnummer",
      },
      inputs: {
        isMarketingConsentGranted: {
          falseLabel: "Ohne Opt-in",
          nullLabel: "Entweder",
          trueLabel: "Mit Opt-in",
        },
      },
      lastSalesforceSync: "Zuletzt synchronisiert: %{age}",
      lastSalesforceSyncPreparing: "wird vorbereitet",
      list: {
        chooseChannel: "Wählen Sie einen Kanal:",
      },
      name: "Kontakte",
      successMessages: {
        tagAdded: "Tag hinzugefügt",
        tagRemoved: "Tag entfernt",
      },
    },
    merchant_users: {
      actions: {
        create: "Hinzufügen",
      },
      chooseLanguage: "Sprache gewählt",
      customFields: {
        myFlinkitNumbers: {
          label: "Meine Flinkit-Nummern",
        },
      },
      edit: {
        title: "Profil",
      },
      emailNotification: {
        choices: {
          instant: {
            description: "Sobald eine neue Nachricht in Deinen Chats eingeht, erhältst " +
              "Du eine Email.",
            label: "Sofort-Benachrichtigungen in Echtzeit",
          },
          none: {
            description: "Du erhältst keine Benachrichtigungen per Email.",
            label: "Alle Benachrichtigungen ausschalten",
          },
          reminders: {
            description: "Du erhältst maximal 3 mal am Tag eine zusammenfassende Email." +
              "Sowie eine extra Email falls Dir ein Kunden-Chat zugewiesen wurde.",
            label: "3 Erinnerungen pro Tag",
          },
        },
        title: "Email-Benachrichtigungen",
      },
      errorMessages: {
        MERCHANT_USER_EMAIL_ADDRESS_ALREADY_EXISTS:
          'Es existiert bereits ein anderer Kontakt mit dieser E-Mail-Adresse',
        invalidEmailAddress: "Ungültige Emailaddresse",
      },
      fields: {
        emailAddress: "E-Mail-Adresse",
        firstName: "Vorname",
        language: "Sprache",
        lastName: "Nachname",
      },
      name: "Benutzer",
      successMessages: {
        created: "Nutzer erfolgreich hinzugefügt",
      },
    },
    merchants: {
      actions: {
        create: "Standort erstellen",
      },
      errorMessages: {
        create: "Beim Erstellen des Standorts ist ein Fehler aufgetreten",
      },
      fields: {
        companyName: "Name Standort",
        ownerEmailAddress: "Email",
        ownerFirstName: "Vorname",
        ownerLastName: "Nachname",
        whatsappBusinessAbout: "Über das Unternehmen",
        whatsappBusinessAddress: "Adresse",
        whatsappBusinessDescription: "Unternehmensbeschreibung",
        whatsappBusinessEmail: "E-Mail-Adresse",
        whatsappBusinessVertical: "Geschäftszweig",
        whatsappBusinessWebsite: "Webseite",
      },
      location: "Standort",
      owner: "Owner",
      successMessages: {
        created: "Ein neuer Standort wurde erfolgreich erstellt",
      },
    },
    organization_merchant_users: {
      customFields: {
        name: {
          label: "Name",
        },
      },
      fields: {
        emailAddress: "E-Mail-Adresse",
        merchantId: "Standort",
      },
    },
    whatsapp_accounts: {
      fields: {
        businessAbout: "Über das Unternehmen",
        businessAddress: "Adresse",
        businessDescription: "Unternehmensbeschreibung",
        businessEmail: "E-Mail-Adresse",
        businessWebsite: "Webseite",
        whatsappBusinessVertical: "Geschäftszweig",
      },
    },
  },
  sandboxSettings: {
    /* eslint-disable max-len */
    contactUs: {
      button: "Kontaktiere uns",
      subtitle: "Hast Du Fragen oder brauchst Du Hilfe?",
      title: "Beratung anfordern",
    },
    errorMessages: {
      'CHAT_ALREADY_OWNED_BY_ANOTHER_SANDBOX_USER': "Diese Nummer wurde bereits von einem anderen Benutzer verwendet/getestet. Bitte verwende eine andere Nummer",
    },
    featureNotAvailable: "Diese Funktion ist in der kostenlosen Testphase nicht verfügbar",
    requestConversion: {
      button: "Einrichtung starten",
      caption: "Ca 15 Minuten",
      response: "Deine Anfrage wurde erhalten. Wir melden uns bald bei Dir",
      subtitle: "Verbinde Deine Nummer und richte die Whatsapp Business API ein",
      title: "WhatsApp Business API",
    },
    testSandbox: {
      button: "Jetzt testen",
      subtitle: "Teste unseren Eingang und versende Nachrichten per Whatsapp",
      title: "Test (Sandbox)",
    },
    testSandboxDialog: {
      button: "Senden",
      fields: {
        phoneNumber: "WhatsApp-Telefonnummer",
      },
      onIncomingChatMessageNotification: "Habe eine neue Nachricht erhalten und gehe zum Chat",
      onSentChatMessage: "Bitte setze auf deinem Mobiltelefon fort.",
      sandboxCodeMessage: "Hi, verbinde mich bitte mit der Flinkit Sandbox: %{code}",
      subtitle1: "Scanne einfach den Code mit deinem Smartphone",
      subtitle2: "Oder Mobilfunknummer eingeben",
      title: "Teste Eingang und Whatsapp Versand",
    },
    /* eslint-enable max-len */
  },
  sidebar: {
    campaigns: "Kampagnen",
    contacts: "Kontakte",
    help: "Hilfe",
    inbox: "Eingang",
    menu: {
      logout: "Abmelden",
    },
    profile: {
      sandboxAccount: "Testkonto",
    },
    teamChat: "Teamchat",
    templates: "Templates",
  },
  statuses: {
    pending: "Ausstehend",
  },
  unreadChatMessagesBrowserNotificationPresenter: {
    newMessageNotification: "Neue Nachricht von %{authorUserDisplayName}",
    newReactionMessageNotification: "%{authorUserDisplayName} reagierte auf eine " +
      "Nachricht",
  },
  validations: {
    companyName: "Firmenname ist erforderlich“",
    companySize: "Unternehmensgröße ist erforderlich",
    date: "Ungültiges Datum",
    emailAddress: "Ungültige Emailaddresse",
    files: {
      maxSize: "Die ausgewählte Dateigröße %{filename} (%{fileSize}MiB) überschreitet " +
        "unsere %{maxSize}MiB-Dateigrößenbeschränkung",
      unexpectedError: "Datei %{filename} konnte nicht angehängt werden",
      unsupportedFileType: "Das angehängte Format %{type} wird nicht unterstützt",
    },
    fullName: "Vollständiger Name ist erforderlich",
    industry: "Industrie ist gefragt",
    password: {
      digit: "Mindestens eine Nummer",
      doNotMatch: "Passwort und Passwortbestätigung stimmen nicht überein",
      length: "Mindestens 8 Zeichen",
      lowercase: "Mindestens ein Kleinbuchstabe",
      missingSomeChars: "Stelle sicher, dass dein Passwort folgende Kriterien erfüllt",
      required: "Passwort wird benötigt",
      uppercase: "Mindestens ein Großbuchstabe",
    },
    phoneNumber: "Ungültige Telefonnummer",
    required: "Erforderlich",
    time: "Ungültige Zeit",
  },
  weekdays: {
    full: {
      friday: "Freitag",
      monday: "Montag",
      saturday: "Samstag",
      sunday: "Sonntag",
      thursday: "Donnerstag",
      tuesday: "Dienstag",
      wednesday: "Mittwoch",
    },
    shortened: {
      friday: "Fr",
      monday: "Mo",
      saturday: "Sa",
      sunday: "So",
      thursday: "Do",
      tuesday: "Di",
      wednesday: "Mi",
    },
  },
  whatsapp_business_verticals: {
    APPAREL: "Bekleidung",
    AUTO: "Autoservice",
    BEAUTY: "Beauty, Kosmetik und Körperpflege",
    EDU: "Bildungswesen",
    ENTERTAIN: "Kunst und Unterhaltung",
    EVENT_PLAN: "Veranstaltungsplaner/in",
    FINANCE: "Finanzwesen",
    GOVT: "Öffentlicher Dienst",
    GROCERY: "Lebensmittelgeschäft",
    HEALTH: "Medizin und Gesundheit",
    HOTEL: "Hotel",
    NONPROFIT: "Gemeinnützige Organisation",
    NOT_A_BIZ: "Kein Geschäft",
    OTHER: "Anderes Gewerbe",
    PROF_SERVICES: "Professionelle Dienstleistung",
    RESTAURANT: "Restaurant",
    RETAIL: "Einzelhandel",
    TRAVEL: "Reisen und Verkehr",
    UNDEFINED: "Nicht definiert",
  },
  whatsapp_message_templates: {
    statusDescriptions: {
      APPROVED: "Dieses Template kann nun in den Chats verwendet werden.",
      /* eslint-disable max-len */
      CREATED: "WhatsApp muss dieses Template noch freigeben. Erst danach kannst du es verwenden.",
      DELETED: "Dieses Template steht nicht mehr zur Verfügung",
      /* eslint-disable max-len */
      PENDING: "WhatsApp muss dieses Template noch freigeben. Erst danach kannst du es verwenden.",
      /* eslint-enable max-len */
      REJECTED: "WhatsApp hat dieses Template abgelehnt.",
    },
    statusLabels: {
      APPROVED: "Freigegeben",
      CREATED: "Warte auf Freigabe",
      DELETED: "Gelöscht",
      PENDING: "Warte auf Freigabe",
      REJECTED: "Abgelehnt",
    },
  },
  whatsapp_messages: {
    lastSendAttemptErrorCode: {
      2: "WhatsApp-Dienst vorübergehend nicht verfügbar aufgrund von Ausfallzeiten " +
        "oder Überlastung.",
      368: "Vorübergehend gesperrt aufgrund von Verstößen gegen die Richtlinien",
      410: (
        "Das Verfallsdatum wurde erreicht bevor die Nachricht versandt werden konnte"
      ),
      429: "Sie haben zu viele Nachrichten in sehr kurzer Zeit versandt",
      471: "Zu viele Ihrer Nachrichten wurden als Spam gemeldet",
      480: "Diese WhatsApp Nummer hat vermutlich einen neuen Besitzer",
      1001: "Die Nachricht ist länger als die erlaubten 4096 Zeichen",
      1009: "Die Nachricht enthält ungültige Attribute",
      1013: "Diese Telefonnummer hat noch kein WhatsApp eingerichtet",
      1026: "Der Empfänger kann diese Nachricht nicht empfangen",
      1028: "Diese WhatsApp Nummer hat vermutlich einen neuen Besitzer",
      2003: "Der Empfänger konnte das Nachrichten-Template nicht empfangen",
      2008: "Das Format des Dateianhangs wird nicht von WhatsApp unterstützt",
      80007: "Das WhatsApp Business-Konto hat sein Rate-Limit erreicht.",
      131026: "Nachricht kann nicht zugestellt werden. Die Telefonnummer des " +
        "Empfängers ist keine WhatsApp-Telefonnummer oder hat unsere neuen " +
        "Nutzungsbedingungen nicht akzeptiert oder verwendet eine alte WhatsApp-Version.",
      131031: "Konto wurde gesperrt",
      131048: "Spam-Rate-Limit erreicht",
      131053: "Es ist nicht möglich, das in der Nachricht verwendete Medium hochzuladen.",
      131056: "(Business Account, Consumer Account) Paar-Rate-Limit erreicht. Bitte " +
        "warten und erneut versuchen, die Nachricht an diesen Empfänger zu senden.",
      TEMPLATE_REJECTED: "Das Template wurde abgelehnt",
    },
    lastSendAttemptErrorCodeToCustomDetails: {
      TEMPLATE_REJECTED: "Deine Nachricht konnte nicht verschickt werden, versuche es " +
        "erneut mit anderem Inhalt, oder verschicke ein Template",
    },
    receivingErrorCode: {
      501: "Nicht unterstützter Nachrichtentyp",
    },
  },
  whatsappAccountBusinessProfileEdit: {
    profilePhotoConstraints: "Empfohlene Dateigröße, 5 KB bis 5 MB. PNG, JPG",
    successfulProfilePhotoUpload: "Profilfoto erfolgreich aktualisiert",
    validations: {
      emailAddress: "Ungültige Emailaddresse",
      profilePhotoSize: "Bitte nutzen Sie das empfohlene Format bzw. die empfohlene " +
        "Größe!",
      websiteUrl: "Die URL der Website muss entweder mit http:// oder https:// beginnen.",
    },
  },
}
